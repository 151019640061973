// Master Fair Sector Routes......
const fairSector = '/itf-configuration/fair-sector/'
export const fairSectorList = fairSector + 'list'
export const fairSectorStore = fairSector + 'store'
export const fairSectorUpdate = fairSector + 'update'
export const fairSectorToggle = fairSector + 'toggle-status'
// Master Committee Name Routes......
const committeeName = '/itf-configuration/committee-name/'
export const committeeNameList = committeeName + 'list'
export const committeeNameStore = committeeName + 'store'
export const committeeNameUpdate = committeeName + 'update'
export const committeeNameToggle = committeeName + 'toggle-status'
// Master Committee Roll Routes......
const committeeRoll = '/itf-configuration/committee-roll/'
export const committeeRollList = committeeRoll + 'list'
export const committeeRollStore = committeeRoll + 'store'
export const committeeRollUpdate = committeeRoll + 'update'
export const committeeRollToggle = committeeRoll + 'toggle-status'
// Master Measurement Unit Routes......
const measurementUnit = '/itf-configuration/measurement-unit/'
export const measurementUnitList = measurementUnit + 'list'
export const measurementUnitStore = measurementUnit + 'store'
export const measurementUnitUpdate = measurementUnit + 'update'
export const measurementUnitToggle = measurementUnit + 'toggle-status'
// Master Committee Info Routes......
const committeeInfo = '/itf-configuration/committee-info/'
export const committeeInfoList = committeeInfo + 'list'
export const committeeInfoStore = committeeInfo + 'store'
export const committeeInfoUpdate = committeeInfo + 'update'
export const committeeInfoToggle = committeeInfo + 'toggle-status'
export const committeeInfoShow = committeeInfo + 'show'
// Master Subsidy Category Routes......
const subsidyCategory = '/itf-configuration/subsidy-category/'
export const subsidyCategoryList = subsidyCategory + 'list'
export const subsidyCategoryStore = subsidyCategory + 'store'
export const subsidyCategoryUpdate = subsidyCategory + 'update'
export const subsidyCategoryToggle = subsidyCategory + 'toggle-status'
export const subsidyCategoryShow = subsidyCategory + 'show'
// Master Checklist Routes......
const evaluationChecklist = '/itf-configuration/evaluation-checklist/'
export const evaluationChecklistList = evaluationChecklist + 'list'
export const evaluationChecklistStore = evaluationChecklist + 'store'
export const evaluationChecklistUpdate = evaluationChecklist + 'update'
export const evaluationChecklistToggle = evaluationChecklist + 'toggle-status'
export const evaluationChecklistShow = evaluationChecklist + 'show'

// Master Stall Type Routes......
const stallCategory = '/itf-configuration/stall-category/'
export const stallCategoryList = stallCategory + 'list'
export const stallCategoryStore = stallCategory + 'store'
export const stallCategoryUpdate = stallCategory + 'update'
export const stallCategoryToggle = stallCategory + 'toggle-status'
export const stallCategoryShow = stallCategory + 'show'
// Master Both Type Routes......
const stallInformation = '/itf-configuration/stall-information/'
export const stallInformationList = stallInformation + 'list'
export const stallInformationStore = stallInformation + 'store'
export const stallInformationUpdate = stallInformation + 'update'
export const stallInformationToggle = stallInformation + 'toggle-status'
export const stallInformationShow = stallInformation + 'show'
export const getStallCatByFairApi = stallInformation + 'stall-cat-by-fair'
// Master Subsidy Information Routes......
const subsidyInformation = '/itf-configuration/subsidy-information/'
export const subsidyInformationList = subsidyInformation + 'list'
export const subsidyInformationStore = subsidyInformation + 'store'
export const subsidyInformationUpdate = subsidyInformation + 'update'
export const subsidyInformationToggle = subsidyInformation + 'toggle-status'
export const subsidyInformationShow = subsidyInformation + 'show'
// Master Report Heading Routes......
const reportHead = '/itf-configuration/report-head/'
export const reportHeadList = reportHead + 'list'
export const reportHeadStore = reportHead + 'store'
export const reportHeadUpdate = reportHead + 'update'
export const reportHeadToggle = reportHead + 'toggle-status'
// Master Fair Participation Note Routes......
const fairParticipationNote = '/itf-configuration/fair-participation-note/'
export const fairParticipationNoteList = fairParticipationNote + 'list'
export const fairParticipationNoteStore = fairParticipationNote + 'store'
export const fairParticipationNoteUpdate = fairParticipationNote + 'update'
export const fairParticipationNoteToggle = fairParticipationNote + 'toggle-status'
// Master Fair Evaluation Routes......
const fairEvaluation = '/itf-configuration/fair-evaluation/'
export const masterFairEvaluationListApi = fairEvaluation + 'list'
export const masterFairEvaluationStoreApi = fairEvaluation + 'store'
export const masterFairEvaluationUpdateApi = fairEvaluation + 'update'
export const masterFairEvaluationToggleApi = fairEvaluation + 'toggle-status'
